// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type MarkFullColorIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function MarkFullColorIcon(props: MarkFullColorIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox="0 0 265 384"
      className={classNames(
        "plasmic-default__svg",
        className,
        "plasmic-default__svg plasmic_default__all plasmic_default__svg ComponentTceyg5SPl6__svg__mwBdj"
      )}
      role={"img"}
      height={"1em"}
      width={"1em"}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d="M128.032 0.367065C124.357 9.98245 119.793 19.2288 115.773 28.7006L91.9056 85.5495C83.9351 104.356 64.2096 149.756 63.4993 170.198C49.2592 160.788 33.1389 155.022 18.669 144.494C31.1984 163.326 40.854 184.14 50.9638 204.334C65.3787 233.128 80.5642 261.589 95.8506 289.931C103.232 303.617 110.702 317.283 115.706 332.059C117.388 341.122 119.234 350.337 121.477 359.272C122.487 363.299 125.434 371.385 125.61 375.029C122.649 371.784 117.395 359.796 114.831 355.222C104.763 337.258 94.9534 320.476 85.2195 302.213L33.0982 202.297C25.3612 187.437 17.6965 172.506 10.2729 157.488C6.70308 150.266 2.12762 142.763 0.0319824 134.982C2.33703 131.846 5.43002 129.08 8.11021 126.242L73.7613 57.6622C79.6828 51.44 85.3747 45.0183 91.1893 38.6982L109.668 18.929C114.568 13.6677 122.371 4.45703 128.032 0.367065Z"
        fill="#064789" />
      <path
        d="M115.706 332.059C109.938 278.944 95.0604 239.281 76.348 189.202C74.6562 184.672 71.4954 178.787 70.3037 174.464C69.2174 170.525 76.7699 177.487 77.2798 177.867C104.891 198.386 137.151 198.348 168.717 187.361C177.385 184.344 186.562 179.832 195.445 177.744C191.282 193.208 183.173 207.064 178.028 222.169C161.96 269.345 150.321 318.587 136.847 366.702C134.16 376.296 133.675 395.255 125.61 375.029C125.434 371.385 122.487 363.299 121.477 359.272C119.234 350.337 117.388 341.122 115.706 332.059Z"
        fill="#427AA1" />
      <path
        d="M147.374 0.349365C149.927 0.940845 170.959 27.136 174.595 31.3341L235.845 102.772C242.227 110.107 248.75 117.328 255.264 124.546C268.369 139.065 267.187 133.15 257.899 150.793C253.779 158.62 249.763 166.537 245.791 174.441L206.256 253.03C192.816 279.601 179.318 306.154 164.659 332.079C160.434 339.55 150.166 358.645 144.078 364.37C146.523 358.83 148.33 353.058 150.193 347.306L169.001 280.475C176.671 253.553 185.173 226.818 195.546 200.807C201.144 186.773 198.783 190.734 211.274 182.812C224.146 174.65 248.01 160.742 255.433 147.437L255.611 147.116C236.132 156.367 220.558 168.537 202.771 179.718C203.12 161.063 187.79 116.344 180.642 95.981L150.814 11.676C149.598 8.19152 147.625 4.02359 147.374 0.349365Z"
        fill="#427AA1" />
      <path
        d="M132.335 0.280148C135.249 0.130245 138.156 0.14403 141.067 0.314574C142.049 7.44879 146.132 17.1442 148.57 24.2495C164.427 70.4612 185.943 122.387 195.537 170.19C175.507 180.408 153.502 188.019 131.222 191.229L130.819 191.27C112.18 193.026 97.3443 185.169 81.8997 176.285C77.2776 173.626 72.1417 171.137 68.0333 167.714C67.7093 148.846 88.3373 103.933 95.9914 86.1559L116.567 37.5065C119.868 29.6279 128.797 5.0675 132.335 0.280148Z"
        fill="#EBF2FA" />

    </svg>
  );
}

export default MarkFullColorIcon;
/* prettier-ignore-end */

// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import { useGetDomainsForProject, useGetProjectReleases } from "@/wab/client/api-hooks";
import { AppCtx } from "@/wab/client/app-ctx";
import {
  useAppAccessRules,
  useAppAuthConfig,
  useAppRoles,
  useDirectoryGroups,
  useMutateHostAppAuthData,
} from "@/wab/client/components/app-auth/app-auth-contexts";
import { APP_AUTH_TRACKING_EVENT } from "@/wab/client/components/app-auth/constants";
import PermissionRule from "@/wab/client/components/app-auth/PermissionRule";
import { Spinner } from "@/wab/client/components/widgets";
import Button from "@/wab/client/components/widgets/Button";
import Chip from "@/wab/client/components/widgets/Chip";
import {
  DefaultPermissionsTabProps,
  PlasmicPermissionsTab,
} from "@/wab/client/plasmic/plasmic_kit_end_user_management/PlasmicPermissionsTab";
import { isUserProjectEditor } from "@/wab/client/studio-ctx/StudioCtx";
import { trackEvent } from "@/wab/client/tracking";
import { ensure, isValidEmail, withoutFalsy, withoutNils } from "@/wab/shared/common";
import { DEVFLAGS } from "@/wab/shared/devflags";
import { ApiAppEndUserAccessRule, ApiProject } from "@/wab/shared/ApiSchema";
import { DomainValidator } from "@/wab/shared/hosting";
import { prodUrlForProject } from "@/wab/shared/project-urls";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { notification, Select, Tag, Tooltip } from "antd";
import { uniqBy, without } from "lodash";
import * as React from "react";
import { useEffect, useState } from "react";
import validator from "validator";

export interface PermissionsTabProps extends DefaultPermissionsTabProps {
  directoryId: string;
  project: ApiProject;
  appCtx: AppCtx;
}

const GENERAL_ACCESS_TOOLTIP = (
  <>
    <p>If "Denied", then only the above users/groups can sign in.</p>
    <p>
      Otherwise, anyone can sign in, and they'll be assigned the selected role (unless they match another role further
      up in the list).
    </p>
  </>
);

function PermissionsTab_(props: PermissionsTabProps, ref: HTMLElementRefOf<"div">) {
  const { directoryId, project, appCtx, ...rest } = props;

  const mutateHostAppAuthData = useMutateHostAppAuthData(project.id);

  const { roles, loading: loadingRoles } = useAppRoles(appCtx, project.id);
  const { accesses, mutate: mutateAccesses } = useAppAccessRules(appCtx, project.id);
  const { groups } = useDirectoryGroups(appCtx, directoryId);

  const isEditor = isUserProjectEditor(appCtx.selfInfo, project, appCtx.perms);

  const { config: appAuthConfig, mutate: mutateAppAuthConfig } = useAppAuthConfig(appCtx, project.id);

  const accessesByEmail = accesses.filter((u): u is ApiAppEndUserAccessRule & { email: string } => "email" in u);
  const accessesByExternalId = accesses.filter(
    (u): u is ApiAppEndUserAccessRule & { externalId: string } => "externalId" in u,
  );

  const accessesByGroup = accesses.filter(
    (u): u is ApiAppEndUserAccessRule & { directoryEndUserGroupId: string } => "directoryEndUserGroupId" in u,
  );
  const accessesByDomain = accesses.filter((u): u is ApiAppEndUserAccessRule & { domain: string } => "domain" in u);

  async function changeAccessRole(access: ApiAppEndUserAccessRule, newRoleId: string | undefined | null) {
    if (newRoleId) {
      await mutateAccesses(
        async () => {
          await appCtx.api.updateAccessRule(project.id, access.id, newRoleId);
          return await appCtx.api.listAppAccessRules(project.id);
        },
        {
          optimisticData: accesses.map((u) => {
            if (u.id === access.id) {
              return {
                ...u,
                roleId: newRoleId,
              };
            }
            return u;
          }),
        },
      );

      await mutateHostAppAuthData();
    }
  }

  async function inviteElements(unfilteredEmails: string[], unfilteredDomains: string[], unfilteredGroupIds: string[]) {
    const emails: string[] = [];
    unfilteredEmails.forEach((email) => {
      if (validator.isEmail(email)) {
        if (accessesByEmail.find((u) => u.email === email)) {
          notification.warning({
            message: `Email ${email} has already been added`,
          });
        } else {
          emails.push(email);
        }
      } else {
        notification.warning({
          message: `Email ${email} is not valid`,
        });
      }
    });

    const domains: string[] = [];
    unfilteredDomains.forEach((domain) => {
      if (domain.startsWith("@") && validator.isFQDN(domain.substring(1))) {
        if (accessesByDomain.find((u) => u.domain === domain)) {
          notification.warning({
            message: `Domain ${domain} has already been added`,
          });
        } else {
          domains.push(domain);
        }
      } else {
        notification.warning({
          message: `Domain ${domain} is not valid`,
        });
      }
    });

    const groupIds: string[] = [];
    unfilteredGroupIds.forEach((groupId) => {
      if (accessesByGroup.find((u) => u.directoryEndUserGroupId === groupId)) {
        const group = groups.find((g) => g.id === groupId);
        notification.warning({
          message: `Group ${group?.name} has already been added`,
        });
      } else {
        groupIds.push(groupId);
      }
    });

    if (emails.length === 0 && domains.length === 0 && groupIds.length === 0) {
      return;
    }

    const userRole = roles[roles.length - 1];
    await mutateAccesses(
      async () => {
        await appCtx.api.createAppAccessRules({
          appId: project.id,
          emails,
          externalIds: [],
          directoryEndUserGroupIds: groupIds,
          domains,
          roleId,
          notify: realNotify(),
        });
        return await appCtx.api.listAppAccessRules(project.id);
      },
      {
        optimisticData: [
          ...accesses,
          ...emails.map((email) => ({
            id: email,
            email,
            roleId: userRole.id,
            properties: {},
            // As this is an optimistic update, we mark as fake so that no operations are performed on it
            // until the actual data is returned from the server
            isFake: true,
          })),
          ...domains.map((domain) => ({
            id: domain,
            domain,
            roleId: userRole.id,
            properties: {},
            isFake: true,
          })),
          ...groupIds.map((groupId) => ({
            id: groupId,
            directoryEndUserGroupId: groupId,
            roleId: userRole.id,
            properties: {},
            isFake: true,
          })),
        ],
      },
    );

    trackEvent(APP_AUTH_TRACKING_EVENT, {
      action: "invite",
    });

    if (emails.length > 0) {
      await mutateHostAppAuthData();
    }
  }

  function isDomainEntry(value: string) {
    return value[0] === "@" && new DomainValidator("").isValidDomain(value.slice(1));
  }

  function tryGetGroupId(value: string) {
    if (!(!isDomainEntry(value) && !isValidEmail(value))) {
      return undefined;
    }
    const group = groups.find((g) => g.name === value);
    return group?.id;
  }

  async function inviteCurrentSelection() {
    await inviteElements(
      invites.flatMap((invite) => (isValidEmail(invite) ? [invite] : [])),
      invites.flatMap((invite) => (isDomainEntry(invite) ? [invite] : [])),
      withoutNils(invites.map((invite) => tryGetGroupId(invite))),
    );
  }

  async function removeAccess(access: ApiAppEndUserAccessRule) {
    await mutateAccesses(
      async () => {
        await appCtx.api.deleteAccessRule(project.id, access.id);
        return await appCtx.api.listAppAccessRules(project.id);
      },
      {
        optimisticData: accesses.filter((u) => u.id !== access.id),
      },
    );

    if ("email" in access) {
      await mutateHostAppAuthData();
    }
  }

  const [rawNotify, setRawNotify] = useState(true);

  const [roleId, setRoleId] = useState(roles[roles.length - 1]?.id);

  const [invites, setInvites] = useState<string[]>([]);
  const handleChange = (values_: string[]) => {
    console.log(`selected ${values_}`);
    setInvites(values_.map((v) => v.trim().toLowerCase()));
  };
  const [search, setSearch] = useState("");
  const handleSearch = (searchValue: string) => {
    setSearch(searchValue);
  };
  const suggestedEmails: string[] = []; // TODO replace with real suggested emails

  // We need to keep option values as plain strings since this is what Ant Select does the typeahead search over. Can't just parse these into arbitrary JSON values or else those stringified JSONs are what you end up searching.
  const options = uniqBy(
    withoutFalsy([
      ...suggestedEmails.map((email) => ({
        label: <>Add {email}</>,
        value: email,
      })),
      ...groups.map((group) => ({
        label: (
          <div>
            Add group <strong>{group.name}</strong>
          </div>
        ),
        value: group.name,
      })),
      isValidEmail(search.trim()) && {
        label: "Add " + search,
        value: search,
      },
      isDomainEntry(search) && {
        label: `Add anyone${search}`,
        value: search,
      },
    ]).filter(({ value }) => !invites.includes(value)),
    ({ value }) => value,
  );

  const [submitting, setSubmitting] = useState(false);

  const anyEmails = invites.some((v) => isValidEmail(v));

  const [selecting, setSelecting] = useState(false);

  useEffect(() => {
    setRoleId(roles[roles.length - 1]?.id);
  }, [loadingRoles]);

  const { data: domainsResult, isLoading: loadingDomains } = useGetDomainsForProject(project.id);

  const { data: releases, isLoading: loadingReleases } = useGetProjectReleases(project.id);

  if (loadingRoles || loadingDomains || loadingReleases) {
    return <Spinner />;
  }

  const prodUrl = prodUrlForProject(DEVFLAGS, project, domainsResult?.domains ?? []);
  const hasVersions = (releases ?? []).length > 0;
  const published = !!prodUrl && hasVersions;

  const handleSubmit = async () => {
    setSubmitting(true);
    await inviteCurrentSelection();
    setSubmitting(false);
    setSearch("");
    setInvites([]);
  };

  function realNotify() {
    return rawNotify && published;
  }

  return (
    <>
      <PlasmicPermissionsTab
        root={{ ref }}
        // TODO This wrapping is needed due to Plasmic codegen styling bug.
        addBtn={{
          wrap: () => (
            <Button type="primary" disabled={submitting || !isEditor} htmlType={"submit"}>
              {anyEmails && realNotify() ? "Invite" : "Add"}
            </Button>
          ),
        }}
        form={{
          onSubmit: async (e) => {
            e.preventDefault();
            await handleSubmit();
          },
        }}
        notifyCheckbox={{
          wrap: (node) =>
            anyEmails && (
              <Tooltip
                title={
                  published
                    ? `Applies only to emails, not @domains or groups. Will be invited to ${prodUrl}.`
                    : "Project must first be published at some URL, or there's nowhere to invite users to!"
                }
              >
                <div>{node}</div>
              </Tooltip>
            ),
          props: {
            style: { opacity: submitting || !published ? 0.5 : 1 },
            disabled: submitting || !published,
            isChecked: realNotify(),
            onChange: (checked) => setRawNotify(checked),
          },
        }}
        roleSelect={{
          wrap: () => (
            <Select
              {...{
                style: { fontSize: 12, width: "200px" },
                disabled: submitting,
                options: roles.map((role) => ({
                  label: role.name,
                  value: role.id,
                })),
                value: roleId,
                onChange: (value) => setRoleId(ensure(value, "")),
              }}
            />
          ),
        }}
        input={{
          wrap: () => {
            return (
              <Select<string[]>
                disabled={submitting}
                suffixIcon={null}
                onDropdownVisibleChange={(open) => setSelecting(open)}
                onKeyDown={async (e) => {
                  if (!search && e.key === "Enter") {
                    await handleSubmit();
                  }
                }}
                mode="multiple"
                notFoundContent={
                  <div
                    style={{
                      padding: 16,
                      textAlign: "center",
                      color: "rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    Enter a valid email, a @domain.com, or a group name
                  </div>
                }
                tokenSeparators={[","]}
                tagRender={(option) => {
                  return (
                    <Tag closable onClose={() => setInvites(without(invites, option.value))}>
                      {option.value}
                    </Tag>
                  );
                }}
                style={{ width: "100%", fontSize: 12 }}
                placeholder="Add people or groups"
                onChange={handleChange}
                onSearch={handleSearch}
                value={invites}
                searchValue={search}
                onSelect={() => setSearch("")}
                options={options}
              />
            );
          },
        }}
        mainRules={{
          children: [
            ...accessesByDomain.map((access) => {
              return (
                <PermissionRule
                  key={access.id}
                  isFake={access.isFake}
                  ruleName={
                    <>
                      Anyone from <Chip>{access.domain}</Chip>
                    </>
                  }
                  roles={roles}
                  value={access.roleId}
                  isGroup
                  hasMenu
                  onChange={async (newRoleId) => {
                    await changeAccessRole(access, newRoleId);
                  }}
                  onRemove={async () => {
                    await removeAccess(access);
                  }}
                />
              );
            }),
            ...accessesByGroup.map((access) => {
              const group = groups.find((g) => g.id === access.directoryEndUserGroupId);

              if (!group) {
                return null;
              }
              return (
                <PermissionRule
                  key={access.id}
                  isFake={access.isFake}
                  ruleName={
                    <>
                      Group <Chip>{group.name}</Chip>
                    </>
                  }
                  roles={roles}
                  value={access.roleId}
                  isGroup
                  hasMenu
                  onChange={async (newRoleId) => {
                    await changeAccessRole(access, newRoleId);
                  }}
                  onRemove={async () => {
                    await removeAccess(access);
                  }}
                />
              );
            }),
            ...accessesByEmail.map((access, idx) => {
              return (
                <PermissionRule
                  key={access.id}
                  ruleName={access.email}
                  roles={roles}
                  value={access.roleId}
                  isFake={access.isFake}
                  hasMenu
                  onChange={async (newRoleId) => {
                    await changeAccessRole(access, newRoleId);
                  }}
                  onRemove={async () => {
                    await removeAccess(access);
                  }}
                />
              );
            }),
            ...accessesByExternalId.map((access, idx) => {
              return (
                <PermissionRule
                  key={access.id}
                  ruleName={`ID: ${access.externalId}`}
                  roles={roles}
                  value={access.roleId}
                  isFake={access.isFake}
                  hasMenu
                  onChange={async (newRoleId) => {
                    await changeAccessRole(access, newRoleId);
                  }}
                  onRemove={async () => {
                    await removeAccess(access);
                  }}
                />
              );
            }),
            <PermissionRule
              key="registered"
              showDenied
              ruleName={"General Access"}
              infoIcon={{
                wrap: (child) => {
                  return <Tooltip title={GENERAL_ACCESS_TOOLTIP}>{child}</Tooltip>;
                },
              }}
              isGeneralAccess
              withoutBorder
              roles={roles}
              value={appAuthConfig?.registeredRoleId}
              onChange={async (_registeredRoleId) => {
                const newRegisteredRoleId = _registeredRoleId === "denied" ? null : _registeredRoleId;
                await mutateAppAuthConfig(
                  async () => {
                    await appCtx.api.upsertAppAuthConfig(project.id, {
                      ...appAuthConfig,
                      registeredRoleId: newRegisteredRoleId,
                    });
                    return await appCtx.api.getAppAuthConfig(project.id);
                  },
                  {
                    optimisticData: {
                      ...appAuthConfig,
                      registeredRoleId: newRegisteredRoleId,
                    },
                  },
                );
              }}
            />,
          ],
        }}
        {...rest}
      />
    </>
  );
}

const PermissionsTab = React.forwardRef(PermissionsTab_);
export default PermissionsTab;

import {
  HeadMetadataContext,
  PlasmicPrepassContext
} from "@plasmicapp/query";
import prepass from "@plasmicapp/react-ssr-prepass";
import React from "react";
async function plasmicPrepassExtract(element, onClientComponentRef) {
  const cache = /* @__PURE__ */ new Map();
  const headMetadata = {};
  try {
    await plasmicPrepass(
      /* @__PURE__ */ React.createElement(PlasmicPrepassContext, { cache }, /* @__PURE__ */ React.createElement(HeadMetadataContext.Provider, { value: headMetadata }, element)),
      onClientComponentRef
    );
  } catch (err) {
    console.warn(`PLASMIC: Error encountered while pre-rendering`, err);
  }
  const filteredCache = Object.fromEntries(
    Array.from(cache.entries()).filter(
      ([key, val]) => !key.startsWith("$swr$") && !key.startsWith("$csq$") && val !== void 0
    )
  );
  const queryData = (() => {
    try {
      return JSON.parse(
        JSON.stringify(
          filteredCache,
          (_key, value) => value !== void 0 ? value : null
        )
      );
    } catch {
      return filteredCache;
    }
  })();
  return {
    queryData,
    headMetadata
  };
}
async function extractPlasmicQueryData(element, onClientComponentRef) {
  return (await plasmicPrepassExtract(element, onClientComponentRef)).queryData;
}
async function plasmicPrepass(element, onClientComponentRef) {
  await prepass(element, void 0, onClientComponentRef);
}
export {
  extractPlasmicQueryData,
  plasmicPrepass,
  plasmicPrepassExtract
};

import { PublicLink } from "@/wab/client/components/PublicLink";
import { absorb, uncontrollable } from "@/wab/client/components/view-common";
import { Icon } from "@/wab/client/components/widgets/Icon";
import { IconButton } from "@/wab/client/components/widgets/IconButton";
import { Textbox, TextboxRef } from "@/wab/client/components/widgets/Textbox";
import { plasmicIFrameMouseDownEvent } from "@/wab/client/definitions/events";
import { useFocusOnDisplayed } from "@/wab/client/dom-utils";
import { VERT_MENU_ICON } from "@/wab/client/icons";
import CloseIcon from "@/wab/client/plasmic/plasmic_kit/PlasmicIcon__Close";
import EyeIcon from "@/wab/client/plasmic/plasmic_kit/PlasmicIcon__Eye";
import EyeClosedIcon from "@/wab/client/plasmic/plasmic_kit/PlasmicIcon__EyeClosed";
import PlusIcon from "@/wab/client/plasmic/plasmic_kit/PlasmicIcon__Plus";
import SearchIcon from "@/wab/client/plasmic/plasmic_kit/PlasmicIcon__Search";
import TrashIcon from "@/wab/client/plasmic/plasmic_kit/PlasmicIcon__Trash";
import DragGripIcon from "@/wab/client/plasmic/plasmic_kit_design_system/PlasmicIcon__DragGrip";
import { cx, ensure, isCurrentlyWithinPath, makeCancelable, maybe } from "@/wab/shared/common";
import { createFakeEvent, MaybeWrap, swallowClick, useReadablePromise } from "@/wab/commons/components/ReactUtil";
import { XDraggable, XDraggableEventHandler } from "@/wab/commons/components/XDraggable";
import { ReadablePromise } from "@/wab/commons/control";
import { Dropdown, Table, Tooltip } from "antd";
import classNames from "classnames";
import { isKeyHotkey } from "is-hotkey";
import L from "lodash";
import { observer } from "mobx-react";
import React, { CSSProperties, JSX, Key, ReactElement, ReactNode, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { createPortal } from "react-dom";
import { FaUpload } from "react-icons/fa";

export type HTMLIProps = Omit<JSX.IntrinsicElements["i"], "ref">;
export class DropdownArrow extends React.Component<HTMLIProps, {}> {
  render() {
    // This nesting is necessary to vertically center the arrow
    return <i {...this.props} className="fas fa-caret-down minor-icon" />;
  }
}
export type HTMLAnchorProps = Omit<JSX.IntrinsicElements["a"], "ref">;
export function LinkButton(props: React.ComponentProps<"button">) {
  const { className, ...rest } = props;
  return <button className={cx("link-like", className)} {...rest} />;
}
export interface PlainLinkButtonProps extends React.ComponentProps<"button"> {
  "data-test-id"?: string;
  tooltip?: React.ReactNode;
}
export function PlainLinkButton(props: PlainLinkButtonProps) {
  const { disabled, onClick, className, tooltip, ...forwardedProps } = props;
  return (
    <MaybeWrap
      cond={!!tooltip}
      wrapper={(x) => <Tooltip title={tooltip}>{x}</Tooltip>}
    >
      <button
        {...forwardedProps}
        className={cx(
          {
            "non-link-btn": true,
            "non-link-btn--disabled": disabled,
          },
          className
        )}
        onClick={disabled ? undefined : onClick}
        tabIndex={disabled ? -1 : 0}
        disabled={disabled}
      />
    </MaybeWrap>
  );
}

export const PlainLink = (
  { href, className, activeClassName, disabled, ...forwardedProps }: React.ComponentProps<"a"> & {
    disabled?: boolean;
    activeClassName?: string;
  }
) =>
  (
    <PublicLink
      {...forwardedProps}
      className={cx(
        {
          "plain-link": true,
          "plain-link--disabled": disabled
        },
        className,
        href && isCurrentlyWithinPath(href) ? activeClassName : undefined
      )}
      tabIndex={disabled ? -1 : 0}
      href={href}
    />
  );

export const IconLinkButton = (props: PlainLinkButtonProps) => (
  <PlainLinkButton
    {...props}
    className={`icon-link-btn ${props.className}`}
  />
);

export class HGroup extends React.Component<
  { children?: React.ReactNode },
  {}
> {
  render() {
    return <div className={"hgroup"}>{this.props.children}</div>;
  }
}
export class DropdownPos {
  my: string;
  at: string;
  of?: Element | Document | JQuery.EventBase | Event | React.SyntheticEvent;
  constructor(args: DropdownPos) {
    Object.assign(this, L.pick(args, "my", "at", "of"));
  }
}
const canOptionValuesBeKeys = (options: Option[]) => (
  [...options]
    .map(({ value }) => L.isString(value))
    .every(L.identity) &&
  new Set(
    [...options].map(({ value }) => value)
  ).size === options.length
);

interface Option<T extends {} = {}> {
  key?: Key;
  contents: () => ReactNode;
  value: T;
}

type ModalProps = {
  className?: string;
  children: ReactNode;
};
export class Modal extends React.Component<ModalProps, {}> {
  render() {
    return (
      <div className={cx("modal", this.props.className)}>
        {this.props.children}
      </div>
    );
  }
}
type FixedCenteredProps = {
  fill?: boolean;
  children?: React.ReactNode;
};
// filled means we use a container style that is stretched to span most of the
// window height, otherwise we just use the children's innate sizing and center
// it.
export class FixedCentered extends React.Component<FixedCenteredProps, {}> {
  render() {
    return !this.props.fill ? (
      <div className={"fixed-centered__container-outer"}>
        <div className={"fixed-centered__container-middle"}>
          <div className={"fixed-centered__container-inner"}>
            {this.props.children}
          </div>
        </div>
      </div>
    ) : (
      <div className={"fixed-centered__glass"}>
        <div className={"fixed-centered__filled-container"}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
export class Spinner extends React.Component<{}, {}> {
  render() {
    return (
      <div className={"loader-container"}>
        <div className={"loader"}>{"Loading..."}</div>
      </div>
    );
  }
}
type LoadableProps<T> = {
  loader: () => Promise<T>;
  contents: (data: T) => ReactNode;
  loadingContents: React.ComponentType;
};

export const Loadable = <T = undefined>({ contents, loader, loadingContents: LoadingContents = Spinner }: LoadableProps<T>) => {
  const [loaded, setLoaded] = useState(false)
  const [data, setData] = useState<T | null>(null)
  useEffect(() => {
    const pload = makeCancelable(loader());
    void pload.promise.then(newData => {
      setData(newData)
      setLoaded(true)
    })
    return () => pload.cancel()
  }, [])
  if (loaded) {
    return contents(
      ensure(data, "Data state not defined in Loadable component")
    );
  } else {
    return <LoadingContents/>;
  }
}

export function ReadablePromiseLoadable<T, Err>({
  rp,
  contents,
  failureContents = () => null,
  loadingContents = () => <Spinner />,
}: {
  rp: ReadablePromise<T, Err>;
  loadingContents?: () => ReactElement | null;
  contents: (x: T) => ReactElement | null;
  failureContents?: (x: Err) => ReactElement | null;
}) {
  const result = useReadablePromise(rp);
  if (!result) {
    return loadingContents();
  }
  return result.match({
    success: contents,
    failure: failureContents,
  });
}

export const ObserverLoadable = observer(Loadable);

export class Tab {
  name: ReactNode;
  contents: () => any;
  key: string;
  pullRight?: boolean;
  constructor(args: Tab) {
    Object.assign(this, L.pick(args, "name", "contents", "key", "pullRight"));
  }
}
type _TabsProps = {
  tabs: Tab[];
  tabBarClassName?: string;
  className?: string;
  tabKey?: any;
  onSwitch?: (...args: any[]) => any;
  tabClassName?: any;
  activeTabClassName?: any;
  useDefaultClasses?: boolean;
  barWrapper: (bar: React.ReactNode) => any;
  contentWrapper: (...args: any[]) => any;
  scrollByTransform?: boolean;
  forceRender?: boolean;
  tabBarExtraContent?: React.ReactNode;
};

class _Tabs extends React.Component<_TabsProps, {}> {
  static defaultProps = {
    className: "",
    useDefaultClasses: true,
    barWrapper: L.identity,
    contentWrapper: L.identity,
  };
  render() {
    const tabKey = this.props.tabKey ?? 0;
    return (
      <div className={cx("tabs", this.props.className)}>
        {this.props.barWrapper(
          <div
            className={cx("nav-tabs", this.props.tabBarClassName)}
            role="tablist"
          >
            <div>
              {this.props.tabs.map((tab: /*TWZ*/ Tab, i: /*TWZ*/ number) => {
                const key = tab.key != null ? tab.key : i;
                const isActive = tabKey === key;
                return (
                  <button
                    key={key}
                    className={cx(
                      {
                        "nav-tab": this.props.useDefaultClasses,
                        "nav-tab--active":
                          isActive && this.props.useDefaultClasses,
                        "nav-tab--pull-right":
                          this.props.tabs.length > 1 && tab.pullRight,
                      },
                      this.props.tabClassName,
                      tabKey === key ? this.props.activeTabClassName : undefined
                    )}
                    id={`nav-tab-${key}`}
                    onClick={() => {
                      if (typeof this.props.onSwitch === "function") {
                        this.props.onSwitch(key);
                      }
                      return this.setState({ tabKey: key });
                    }}
                    data-test-tabkey={key}
                    tabIndex={0}
                    aria-selected={isActive}
                    role="tab"
                  >
                    {tab.name}
                  </button>
                );
              })}
            </div>
            {this.props.tabBarExtraContent}
          </div>
        )}

        <div
          className={"tab-content"}
          style={this.props.scrollByTransform ? { overflow: "hidden" } : {}}
        >
          {this.props.tabs.map((tab, i) => {
            const isSelected = tabKey === (tab.key ?? i);
            if (!isSelected && !this.props.forceRender) {
              return null;
            }
            return (
              <div
                className="vlist-scrollable-descendant"
                key={tab.key ?? i}
                style={{
                  display: isSelected ? "flex" : "none",
                }}
              >
                {this.props.contentWrapper(tab.contents())}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
export const Tabs = uncontrollable(_Tabs, _Tabs.defaultProps, {
  tabKey: "onSwitch",
});

type IconButtonSwitchProps<ValueType> = {
  value?: ValueType;
  buttonClass?: string;
  containerClass?: string;
  deselectValue?: ValueType;
  onChange: (v: ValueType | undefined) => any;
  options: {
    title?: React.ReactNode;
    value: ValueType;
    contents: (...args: any[]) => any;
    key?: any;
    disabled?: boolean;
  }[];
  noDeselect?: boolean;
};

export const IconButtonSwitch = <ValueType extends string>(props: IconButtonSwitchProps<ValueType>) => {
  const valuesCanBeKeys = canOptionValuesBeKeys(props.options);
  return (
    <div className={cx("icon-btn-switch", props.containerClass)}>
      {props.options.map(
        ({ key, title, value, contents, disabled }, index) =>
          (
            <IconButton
              key={key != null ? key : valuesCanBeKeys ? value : index}
              tooltip={title || L.capitalize(value)}
              className={props.buttonClass}
              isActive={props.value === value}
              onClick={() => {
                if (props.value === value && props.noDeselect) {
                  return;
                }
                props.onChange(
                  props.value === value
                    ? props.deselectValue
                    : value
                );
              }}
              disabled={disabled}
            >
              {contents()}
            </IconButton>
          )
      )}
    </div>
  );
};

export const ButtonSwitch = IconButtonSwitch;

export type NumSpinnerProps = {
  value?: number;
  incr?: (...args: any[]) => any;
  decr?: (...args: any[]) => any;
  onChange: (...args: any[]) => any;
};

interface DragItemProps {
  dragHandle: () => ReactNode;
  onDragStart?: XDraggableEventHandler;
  onDrag?: XDraggableEventHandler;
  onDragEnd?: XDraggableEventHandler;
  children: ReactNode;
  minPx?: number;
}

export const DragItem = ({
  onDragStart = () => {},
  onDrag = () => {},
  onDragEnd = () => {},
  children,
  dragHandle,
  minPx,
}: DragItemProps) => (
  <XDraggable
    minPx={minPx}
    onStart={onDragStart}
    onDrag={onDrag}
    onStop={onDragEnd}
    render={(e) => (
      <div className={"pass-through no-select"}>
        {e &&
          e.data.started &&
          createPortal(
            <div
              className={"DragItem__Handle"}
              style={{
                left: e.mouseEvent.pageX,
                top: e.mouseEvent.pageY,
              }}
            >
              {dragHandle()}
            </div>,
            document.body
          )}
        {children}
      </div>
    )}
  />
);

type NormalModalProps = {
  title: string;
  className?: any;
  children?: React.ReactNode;
};
// To be used with modal-content
export const NormalModal = (props: NormalModalProps) =>
  <Modal className={props.className}>
  <h2 className={"modal-title"}>{props.title}</h2>
  {props.children}
</Modal>


export const InputBox = (props: JSX.IntrinsicElements["button"] & {placeholder?: string}) => <LinkButton
  {...props}
  className={cx({ "input-box": true }, props.className)}
>
  {React.Children.count(props.children) === 0
    ? props.placeholder
    : props.children}
</LinkButton>

type OnOffSwitchProps = {
  value?: boolean;
  onChange?: (...args: any[]) => any;
  className?: string;
};
export class OnOffSwitch extends React.Component<OnOffSwitchProps, {}> {
  render() {
    const { value, onChange, className } = this.props;
    return (
      <div
        className={classNames("on-off-switch", className)}
        onClick={onChange != null ? absorb(() => onChange(!value)) : undefined}
      >
        <div
          className={classNames({
            "on-off-switch__label": true,
            "on-off-switch__label--active": value === false,
          })}
        >
          {"Off"}
        </div>

        <div
          className={classNames({
            "on-off-switch__label": true,
            "on-off-switch__label--active": value === true,
          })}
        >
          {"On"}
        </div>
      </div>
    );
  }
}

export interface FileUploaderProps {
  onChange: (files: FileList | null) => void;
  accept?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  children?: React.ReactNode;
}

export function FileUploader(props: FileUploaderProps) {
  const { onChange, accept, style, children } = props;
  const [isDragOver, setDragOver] = React.useState(false);
  return (
    <Tooltip title={"Upload or drag a file here"}>
      <PlainLinkButton
        className={cx("file-uploader", { "file-uploader--over": isDragOver })}
        style={style}
      >
        {children ?? (
          <div className={"fake-upload"}>
            <FaUpload />
          </div>
        )}
        <input
          type="file"
          className={"opaque-file-uploader"}
          onChange={(e) => onChange(e.target.files)}
          accept={accept}
          onDragEnter={() => setDragOver(true)}
          onDragLeave={() => setDragOver(false)}
        />
      </PlainLinkButton>
    </Tooltip>
  );
}

export interface FileUploadLinkProps {
  onChange: (files: FileList | null) => void;
  accept?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  children?: React.ReactNode;
  className?: string;
}

export function FileUploadLink(props: FileUploadLinkProps) {
  const { onChange, accept, style, children, className } = props;
  const inputRef = React.useRef<HTMLInputElement>(null);
  return (
    <PlainLinkButton
      className={cx("link-like", className)}
      style={style}
      onClick={() => {
        inputRef.current?.click();
      }}
    >
      {children}
      <input
        type="file"
        className={"display-none"}
        onChange={(e) => onChange(e.target.files)}
        accept={accept}
        ref={inputRef}
      />
    </PlainLinkButton>
  );
}

/**
 * Either addHoverContent or onAdd or addMenu must bes specified.
 */
interface ListBoxProps {
  addText?: string;
  addNode?: React.ReactNode;
  onAdd?: (...args: any[]) => any;
  addHoverContent?: React.ReactNode;
  appendPrepend?: "append" | "prepend";
  onReorder?: (from: number, to: number) => void;
  addMenu?: React.ReactNode;
  children?: React.ReactNode;
  disabled?: boolean;
  "data-test-id"?: string;
}

type ListBoxState = {
  adding: boolean;
};
export class ListBox extends React.Component<ListBoxProps, ListBoxState> {
  constructor(props) {
    super(props);
    this.state = { adding: false };
  }
  private handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const { onReorder } = this.props;
    if (onReorder) {
      onReorder(result.source.index, result.destination.index);
    }
  };
  render() {
    const { addText, addNode, disabled } = this.props;
    // TODO this is inconsistently firing onmouseleave because the div is
    //  actually getting unmounted and then mounted.  See
    //  https://github.com/facebook/react/issues/6807.
    let addButton = (
      <PlainLinkButton
        ref={"adder"}
        className={"list-box__add-placeholder"}
        onClick={this.props.onAdd}
        type="button"
        disabled={disabled}
        data-test-id={`${this.props["data-test-id"]}-add-btn`}
      >
        <div>
          {addNode ? (
            addNode
          ) : (
            <>
              <Icon icon={PlusIcon} /> {addText}
            </>
          )}
        </div>
      </PlainLinkButton>
    );
    if (this.props.addMenu) {
      addButton = (
        <IFrameAwareDropdownMenu menu={this.props.addMenu}>
          {addButton}
        </IFrameAwareDropdownMenu>
      );
    }
    const adder =
      !addText && !addNode ? null : (
        <div
          onMouseEnter={() => {
            if (this.props.addHoverContent != null) {
              return this.setState({ adding: true });
            }
          }}
          onMouseLeave={() => {
            this.setState({ adding: false });
          }}
        >
          {!this.state.adding ? (
            addButton
          ) : (
            <div className={"list-box__add-placeholder"}>
              {this.props.addHoverContent}
            </div>
          )}
        </div>
      );
    return (
      <DragDropContext onDragEnd={this.handleDragEnd}>
        {this.props.appendPrepend === "prepend" ? adder : undefined}
        <Droppable droppableId={"droppable"}>
          {(provided) => (
            <div ref={provided.innerRef} className={"list-box"}>
              {this.props.children}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        {this.props.appendPrepend === "append" ? adder : undefined}
      </DragDropContext>
    );
  }
}

interface ListBoxItemProps {
  thumbnail?: React.ReactNode;
  mainContent: React.ReactNode;
  /** Triggered on click or on "Enter" key down */
  onClick?: () => void;
  onRemove?: (...args: any[]) => any;
  onToggleHide?: () => void;
  index: number;
  isDragDisabled?: boolean;
  disabled?: boolean;
  isHidden?: boolean;
  alignTop?: boolean;
  className?: string;
  showGrip?: boolean;
  showDelete?: boolean;
  showHide?: boolean;
  clickable?: boolean;
  truncate?: boolean;
  gridThumbnail?: boolean;
  menu?: React.ReactNode | MenuMaker;
  "data-test-id"?: string;
}

// TODO Handle drag reordering
export class ListBoxItem extends React.Component<ListBoxItemProps, {}> {
  randId = Math.random();
  render() {
    const {
      index,
      className,
      isDragDisabled,
      disabled,
      isHidden,
      showGrip = true,
      showDelete = true,
      showHide = false,
      onRemove = () => {},
      onToggleHide = () => {},
      clickable = true,
      truncate = true,
      menu,
    } = this.props;
    return (
      <Draggable
        draggableId={`${this.randId}`}
        index={index}
        isDragDisabled={isDragDisabled}
      >
        {(provided, snapshot) => {
          const child = (
            <>
              <div
                ref={provided.innerRef}
                className={"list-box-item-focusable"}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                // TODO: remove this hack
                // react-beautiful-dnd interface exposes
                // React.DragEventHandler<any>, which results in a type mismatch
                onDragStart={
                  provided.dragHandleProps
                    ?.onDragStart as React.DragEventHandler<HTMLDivElement>
                }
                onKeyDown={(event) => {
                  if (
                    this.props.onClick &&
                    isKeyHotkey("enter", event.nativeEvent)
                  ) {
                    this.props.onClick();
                    event.stopPropagation();
                  }
                }}
              >
                <div
                  className={classNames(className, {
                    "list-box-item flex-vcenter": true,
                    "list-box-item--top": !!this.props.alignTop,
                    "list-box-item--clickable": !!clickable,
                    group: true,
                  })}
                  data-test-id={this.props["data-test-id"]}
                  onClick={this.props.onClick}
                >
                  {this.props.thumbnail && (
                    <div
                      className={classNames({
                        "list-box-item__img": true,
                        "list-box-item__img-grid": this.props.gridThumbnail,
                      })}
                    >
                      {this.props.thumbnail}
                    </div>
                  )}

                  <div
                    className={classNames({
                      "flex flex-vcenter list-box-item__label": true,
                      "list-box-item__label--truncate": !!truncate,
                    })}
                  >
                    {this.props.mainContent}
                  </div>

                  {showGrip && (
                    <div
                      className={"list-box-item__handle opaque-on-group-hover"}
                    >
                      <Icon icon={DragGripIcon} />
                    </div>
                  )}

                  {(showDelete || menu) && (
                    <div
                      className="list-box-item__actions"
                      onClick={(e) => swallowClick(e)}
                    >
                      {showDelete && (
                        <button
                          className={"icon-button opaque-on-group-hover"}
                          type="button"
                          onClick={(ev) => {
                            onRemove();
                            return ev.stopPropagation();
                          }}
                          disabled={disabled}
                          data-test-id={
                            this.props["data-test-id"]
                              ? `${this.props["data-test-id"]}-remove`
                              : undefined
                          }
                        >
                          <Icon icon={TrashIcon} />
                        </button>
                      )}
                      {menu && (
                        <IFrameAwareDropdownMenu menu={menu}>
                          <div className="list-box-item__action opaque-on-group-hover">
                            {VERT_MENU_ICON}
                          </div>
                        </IFrameAwareDropdownMenu>
                      )}
                    </div>
                  )}

                  {showHide &&
                    (isHidden ? (
                      <button
                        className={"icon-button"}
                        onClick={(ev) => {
                          onToggleHide();
                          ev.stopPropagation();
                        }}
                      >
                        <Icon icon={EyeClosedIcon} />
                      </button>
                    ) : (
                      <button
                        className={"icon-button opaque-on-group-hover"}
                        onClick={(ev) => {
                          onToggleHide();
                          ev.stopPropagation();
                        }}
                      >
                        <Icon icon={EyeIcon} />
                      </button>
                    ))}
                </div>
              </div>
            </>
          );
          if (snapshot.isDragging) {
            return createPortal(child, document.body);
          } else {
            return child;
          }
        }}
      </Draggable>
    );
  }
}

export function InlineIcon({ children }: { children: ReactNode }) {
  return <div className={"InlineIcon"}>{children}</div>;
}

export type MenuMaker = (onMenuClicked: () => void) => React.ReactNode;

export function useOnIFrameMouseDown(handler: () => void) {
  React.useEffect(() => {
    document.addEventListener(plasmicIFrameMouseDownEvent, handler);
    return () => {
      document.removeEventListener(plasmicIFrameMouseDownEvent, handler);
    };
  }, [handler]);
}

export const IFrameAwareDropdownMenu = (props: {
  menu: React.ReactNode | MenuMaker;
  children?: ReactNode;
  disabled?: boolean;
  overlayClassName?: string;
  overlayStyle?: CSSProperties;
  onVisibleChange?: (visible: boolean) => void;
}) => {
  const { onVisibleChange } = props;
  const [menuVisible, setMenuVisibleState] = React.useState(false);

  const setMenuVisible = React.useCallback(
    (visible: boolean) => {
      setMenuVisibleState(visible);
      onVisibleChange?.(visible);
    },
    [setMenuVisibleState, onVisibleChange]
  );

  const onIFrameClick = React.useCallback(() => {
    setMenuVisible(false);
  }, [setMenuVisible]);

  useOnIFrameMouseDown(onIFrameClick);

  return (
    <Dropdown
      disabled={props.disabled}
      overlay={() => {
        const { menu } = props;
        const effectiveMenu = (
          L.isFunction(menu) ? menu(() => setMenuVisible(false)) : menu
        ) as React.ReactElement;
        return React.cloneElement(effectiveMenu, {
          onClick: (e) => {
            setMenuVisible(false);
            if (effectiveMenu.props.onClick) {
              effectiveMenu.props.onClick(e);
            }
            e.domEvent.stopPropagation();
          },
        });
      }}
      trigger={["click"]}
      open={menuVisible}
      onOpenChange={setMenuVisible}
      overlayClassName={props.overlayClassName}
      overlayStyle={props.overlayStyle}
      destroyPopupOnHide
    >
      {props.children}
    </Dropdown>
  );
};

export function ClickStopper({
  children,
  passthrough,
  preventDefault,
  style,
  className,
}: {
  children: ReactNode;
  passthrough?: boolean;
  preventDefault?: boolean;
  style?: CSSProperties;
  className?: string;
}) {
  return (
    <div
      className={
        className +
        (passthrough
          ? " pass-through clicker-stopper"
          : " block clicker-stopper")
      }
      style={style}
      onClick={(e) => {
        e.stopPropagation();
        if (preventDefault) {
          e.preventDefault();
        }
      }}
    >
      {children}
    </div>
  );
}

export function SearchBox(
  props: Omit<React.ComponentProps<typeof Textbox>, "prefixIcon" | "suffixIcon">
) {
  const ref = React.useRef<TextboxRef>(null);

  const getInput = React.useCallback(
    () => maybe(ref.current, (x) => x.input()),
    [ref]
  );
  useFocusOnDisplayed(getInput, props.autoFocus);

  const resetInput = (e: React.SyntheticEvent) => {
    if (ref.current) {
      ref.current.setValue("");
      if (props.onChange) {
        // Copied from antd's "antd/lib/input/Input.resolveOnChange"
        // Fakes a ChangeEvent with value ""
        const input = ref.current.input();
        const fakeEvent = createFakeEvent<React.ChangeEvent<HTMLInputElement>>(
          e,
          input
        );
        const originalInputValue = input.value;
        input.value = "";
        props.onChange && props.onChange(fakeEvent);
        input.value = originalInputValue;
      }
    }
  };
  return (
    <Textbox
      prefixIcon={<Icon icon={SearchIcon} />}
      withIcons={["withPrefix", "withSuffix"]}
      suffixIcon={
        props.value &&
        `${props.value}`.trim().length > 0 && (
          <IconButton type="seamless" onClick={(e) => resetInput(e)}>
            <Icon icon={CloseIcon} />
          </IconButton>
        )
      }
      ref={ref}
      {...props}
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          resetInput(e);
        }
      }}
    />
  );
}

/**
 * A version of antd's Table that fills the available vertical space,
 * and sets scroll properly for the table body
 */
export function VerticalFillTable(
  props: React.ComponentProps<typeof Table> & { wrapperClassName?: string }
) {
  const { wrapperClassName, ...rest } = props;
  return (
    <div className={cx("vertical-fill-table", wrapperClassName)}>
      <Table {...rest} />
    </div>
  );
}

export function StudioPlaceholder() {
  return (
    <div className="StudioPlaceholder visible">
      <div className="placeholder_topBar">
        <svg height="40" viewBox="0 0 265 384" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M128.032 0.367065C124.357 9.98245 119.793 19.2288 115.773 28.7006L91.9056 85.5495C83.9351 104.356 64.2096 149.756 63.4993 170.198C49.2592 160.788 33.1389 155.022 18.669 144.494C31.1984 163.326 40.854 184.14 50.9638 204.334C65.3787 233.128 80.5642 261.589 95.8506 289.931C103.232 303.617 110.702 317.283 115.706 332.059C117.388 341.122 119.234 350.337 121.477 359.272C122.487 363.299 125.434 371.385 125.61 375.029C122.649 371.784 117.395 359.796 114.831 355.222C104.763 337.258 94.9534 320.476 85.2195 302.213L33.0982 202.297C25.3612 187.437 17.6965 172.506 10.2729 157.488C6.70308 150.266 2.12762 142.763 0.0319824 134.982C2.33703 131.846 5.43002 129.08 8.11021 126.242L73.7613 57.6622C79.6828 51.44 85.3747 45.0183 91.1893 38.6982L109.668 18.929C114.568 13.6677 122.371 4.45703 128.032 0.367065Z"
            fill="#064789" />
          <path
            d="M115.706 332.059C109.938 278.944 95.0604 239.281 76.348 189.202C74.6562 184.672 71.4954 178.787 70.3037 174.464C69.2174 170.525 76.7699 177.487 77.2798 177.867C104.891 198.386 137.151 198.348 168.717 187.361C177.385 184.344 186.562 179.832 195.445 177.744C191.282 193.208 183.173 207.064 178.028 222.169C161.96 269.345 150.321 318.587 136.847 366.702C134.16 376.296 133.675 395.255 125.61 375.029C125.434 371.385 122.487 363.299 121.477 359.272C119.234 350.337 117.388 341.122 115.706 332.059Z"
            fill="#427AA1" />
          <path
            d="M147.374 0.349365C149.927 0.940845 170.959 27.136 174.595 31.3341L235.845 102.772C242.227 110.107 248.75 117.328 255.264 124.546C268.369 139.065 267.187 133.15 257.899 150.793C253.779 158.62 249.763 166.537 245.791 174.441L206.256 253.03C192.816 279.601 179.318 306.154 164.659 332.079C160.434 339.55 150.166 358.645 144.078 364.37C146.523 358.83 148.33 353.058 150.193 347.306L169.001 280.475C176.671 253.553 185.173 226.818 195.546 200.807C201.144 186.773 198.783 190.734 211.274 182.812C224.146 174.65 248.01 160.742 255.433 147.437L255.611 147.116C236.132 156.367 220.558 168.537 202.771 179.718C203.12 161.063 187.79 116.344 180.642 95.981L150.814 11.676C149.598 8.19152 147.625 4.02359 147.374 0.349365Z"
            fill="#427AA1" />
          <path
            d="M132.335 0.280148C135.249 0.130245 138.156 0.14403 141.067 0.314574C142.049 7.44879 146.132 17.1442 148.57 24.2495C164.427 70.4612 185.943 122.387 195.537 170.19C175.507 180.408 153.502 188.019 131.222 191.229L130.819 191.27C112.18 193.026 97.3443 185.169 81.8997 176.285C77.2776 173.626 72.1417 171.137 68.0333 167.714C67.7093 148.846 88.3373 103.933 95.9914 86.1559L116.567 37.5065C119.868 29.6279 128.797 5.0675 132.335 0.280148Z"
            fill="#EBF2FA" />
        </svg>
      </div>
      <div className="placeholder_leftToolbar"></div>
      <div className="placeholder_leftPanel"></div>
      <div className="placeholder_canvasArea"></div>
      <div className="placeholder_rightPanel"></div>
      <div className="placeholder_loading placeholder_loading--fast"></div>
    </div>
  );
}

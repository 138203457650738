import { apiKey, invalidationKey } from "@/wab/client/api";
import { AppCtx } from "@/wab/client/app-ctx";
import { useAppCtx } from "@/wab/client/contexts/AppContexts";
import { withoutNils } from "@/wab/shared/common";
import { BranchId, ProjectId } from "@/wab/shared/ApiSchema";
import { keyBy } from "lodash";
import useSWR from "swr";

// todo tanstack/react query should go here
// publishFlowDialog should consider `loadingDomains` only on initial loading and here reload happens
// causing state to exist yet be reloading
export function useGetDomainsForProject(projectId: ProjectId) {
  const appCtx = useAppCtx();
  return useSWR(
    appCtx.appConfig.enablePlasmicHosting ? apiKey(`getDomainsForProject`, projectId) : null,
    () => appCtx.api.getDomainsForProject(projectId),
    { revalidateOnMount: true, fallback: { "": undefined } },
  );
}

export function usePlasmicHostingSettings(projectId: ProjectId) {
  const appCtx = useAppCtx();
  return useSWR(
    appCtx.appConfig.enablePlasmicHosting ? apiKey(`getPlasmicHostingSettings`, projectId) : null,
    () => appCtx.api.getPlasmicHostingSettings(projectId),
    { revalidateOnMount: true, fallback: { "": undefined } },
  );
}

export function useGetProjectReleases(projectId: ProjectId) {
  const appCtx = useAppCtx();

  return useSWR(
    invalidationKey(`getProjectReleases`, projectId),
    () => getProjectReleases(appCtx, projectId, undefined),
    { revalidateOnMount: true },
  );
}

export async function getProjectReleases(appCtx: AppCtx, projectId: ProjectId, branchId: BranchId | undefined) {
  const { pkg } = await appCtx.api.getPkgByProjectId(projectId);
  // If we haven't created a Pkg yet, we've never published before
  if (pkg) {
    const pkgVersionResp = await appCtx.api.listPkgVersionsWithoutData(pkg.id, {
      branchId: branchId ?? null,
    });
    return [...pkgVersionResp.pkgVersions];
  } else {
    return [];
  }
}

export function useUsersMap(userIds: (string | undefined | null)[] | undefined | null) {
  const appCtx = useAppCtx();
  const actualUserIds = userIds ? Array.from(new Set(withoutNils(userIds))).sort() : undefined;

  return useSWR(!actualUserIds ? null : `/users-list/${actualUserIds.join(",")}`, async () => {
    if (!actualUserIds) {
      return null;
    }
    if (actualUserIds.length === 0) {
      return {};
    }
    const users = (await appCtx.api.getUsersById(actualUserIds)).users;
    return keyBy(users, (u) => u.id);
  });
}

import { Api } from "@/wab/client/api";
import { ConnectOAuthButton } from "@/wab/client/components/auth/ConnectOAuth";
import { mkShortId, spawn } from "@/wab/shared/common";
import { PromisifyMethods } from "@/wab/commons/promisify-methods";
import { DEVFLAGS } from "@/wab/shared/devflags";
import React from "react";

const githubOAuthURL = "https://github.com/login/oauth/authorize";
const githubInstallURL = `https://github.com/apps/${DEVFLAGS.githubAppName}/installations/new`;

export type AuthType = "oauth" | "install";

export function getURL(type: AuthType, state: string): string {
  return type === "oauth"
    ? `${githubOAuthURL}?client_id=${DEVFLAGS.githubClientId}&state=${state}&redirect_uri=${encodeURIComponent(`${window.location.origin}/github/callback`)}`
    : `${githubInstallURL}?state=${state}`;
}

export function GithubConnect(props: {
  api: PromisifyMethods<Api>;
  type: AuthType;
  render: React.ComponentType<{ onClick: () => void; isWaiting: boolean }>;
  onSuccess: () => void;
  onFailure?: (reason: string) => void;
  refreshDeps?: any[];
}) {
  const [githubState, setGithubState] = React.useState("");

  React.useEffect(() => {
    const state = mkShortId();
    props.api.addStorageItem("githubState", state);
    setGithubState(state);
  }, props.refreshDeps || []);

  return (
    <ConnectOAuthButton
      onSuccess={props.onSuccess}
      onFailure={props.onFailure}
      url={getURL(props.type, githubState)}
      render={props.render}
      children={null}
    />
  );
}
